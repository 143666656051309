import React, { useState } from 'react'
import DataTable from 'react-data-table-component';


const columns = [
    { name: 'EntryDate', selector: 'entryDate', sortable: true },
    { name: 'EntryNo', selector: 'entryNo', sortable: true },
    { name: 'Customer', selector: 'customer', sortable: true },
    { name: 'Amount', selector: 'amount', sortable: true },

];



const SalesReturn = ({ type, hide }) => {
    console.log("the hide is ----" + hide);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [table, setTable] = useState({
        column: [],
        row: [],
        hide: hide,
        amt: 0.00
    })
    console.log("the hide in table ----" + table.hide);



    //////////////// new logic ///////


    ///////////////

    return (
        <div class="home-content" >
            <div>
                {/* <center>
                            <p style={{ color: "blue" }} id="reportcap" >Sales Reports</p>
                        </center> */}
                <div class="p-2">
                    <form method="get">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="from_date">From Date</label>
                                <input type="date" class="form-control" id="from_date" name="from_date" required onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="to_date">To Date</label>
                                <input type="date" class="form-control" id="to_date" name="to_date" onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="code">Report</label>
                                <input type="text" class="form-control" value="Sales Report" />
                            </div>
                        </div>
                    </form>
                </div>
                <center>
                    <button class="button button2 " onClick={myFunction} >Show</button>
                </center>
                <div className=' mt-2 ' style={{ color: "red" }}>
                    {table.hide === true ?
                        (null)
                        :
                        (
                            // table.row != null ?
                            <DataTable
                                pagination
                                direction='auto'
                                // fixedHeader
                                // responsive
                                // fixedHeaderScrollHeight='400'
                                highlightOnHover
                                style={{ color: "red" }}
                                // paginationPerPage={10}
                                // paginationRowsPerPageOptions={[10]}
                                // paginationComponent={Pagination}
                                // style={{ maxHeight: "300px",  }}
                                columns={table.column}

                                data={table.row}

                            />
                            //  : null
                        )


                    }


                </div >
                {table.row.length !== 0 ?

                    (

                        <div className=" bg-info">
                            <span className='pr-5 mr-3' style={{
                                padding: "10px",
color:"white",
                                fontSize: "larger",
                                fontweight: "bolder"
                            }}> Total Amount : {table.amt}</span>

                        </div>
                    )
                    : (null)
                }



            </div>
        </div>




    )


    ////////////  function  



    function myFunction() {
        setTable({ column: [], row: [] })
        // var sdate = document.getElementById("from_date").value;
        // var edate = document.getElementById("to_date").value;
        var code = document.getElementById("code").value;
        var url = "";
        url = "http://111.118.177.198:8099/api/Reports/" + code + "/" + startDate + "/" + endDate + "/" + type;
        //alert(url);
        fetch(url).then((data) => {
            console.log("********");
            console.log(data);
            console.log("********");

            return data.json();
        }).then((objectData) => {
            var obj = JSON.parse(objectData);
            console.log(obj.length + "dftgyuhij");
            // let tableData = "";
            let Amount = 0;

            var res = []
            //// new logic ////

            // var res2 = [];

            //////////


            obj.map((values) => {
                var dateString = values.EntryDate;
                const D = new Date(dateString);
                Amount = Amount + values.GrandTotal;
                const result = D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();

                console.log("////////////////");
                console.log(values);
                res.push({ "entryDate": result, "entryNo": values.EntryNo, "customer": values.Customer, "amount": values.GrandTotal })

                console.log("////////////////");
                /////// new logic ////
                //    res2.push({ "entryDate": result, "entryNo": values.EntryNo, "customer": values.Customer, "amount": Amount })

            });





            setTable({
                column: columns,
                row: res,
                hide: false,
                amt: Amount
            })





        }).catch((err) => {
            console.log(err);
        })
    }



}

export default SalesReturn;
