import React, { useEffect, useState } from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'


const DashBoard = ()=>{
    
    const [option1, setOption1] = useState({
        code:"HAPPYHO2223",
        option: {},
        option2:{},
        cash: 0.00,
        bank: 0.00,
        pay: 0.00,
        receivable: 0.00
    })
    // const [option2, setOption2] = useState({})


    useEffect(() => {

        myFunction()
    }, [])
    return (
        <>
        <div class="home-content">
                    <div class="overview-boxes">
                        <div class="box">
                            <div class="right-side">
                                <div class="box-topic">Cash</div>
                                <div class="number" id="cash">{option1.cash}</div>
                                <div class="indicator">
                                    <i class='bx bx-up-arrow-alt'></i>
                                    <span class="text">Up from yesterday</span>
                                </div>
                            </div>
                            <i class='bx bx-cart-alt cart'></i>
                        </div>
                        <div class="box">
                            <div class="right-side">
                                <div class="box-topic">Bank</div>
                                <div class="number" id="bank">{option1.bank}</div>
                                <div class="indicator">
                                    <i class='bx bx-up-arrow-alt'></i>
                                    <span class="text">Up from yesterday</span>
                                </div>
                            </div>
                            <i class='bx bxs-cart-add cart two' ></i>
                        </div>
                        <div class="box">
                            <div class="right-side">
                                <div class="box-topic">Payable</div>
                                <div class="number" id="pay">{option1.pay}</div>
                                <div class="indicator">
                                    <i class='bx bx-up-arrow-alt'></i>
                                    <span class="text">Up from yesterday</span>
                                </div>
                            </div>
                            <i class='bx bx-cart cart three' ></i>
                        </div>
                        <div class="box">
                            <div class="right-side">
                                <div class="box-topic">Receivable</div>
                                <div class="number" id="Receivable">{option1.receivable}</div>
                                <div class="indicator">
                                    <i class='bx bx-down-arrow-alt down'></i>
                                    <span class="text">Down From Today</span>
                                </div>
                            </div>
                            <i class='bx bxs-cart-download cart four' ></i>
                        </div>
                    </div>
                    <div class="sales-boxes">
                        <div class="recent-sales box">
                            {/* <div class="title">Recent Sales</div> */}
                            {/* <canvas id="myChart" style={{ width: `100%` }}></canvas> */}
                            <CanvasJSChart options={option1.option2} style={{ width: `100%` }} />

                        </div>

                        <div class="top-sales box">
                            <div class="title"></div>
                            <CanvasJSChart options={option1.option} style={{ height: `370px`, width: `100%` }} />
                            {/* <div id="chartContainer" style={{height: `370px`, width: `100%`}}></div> */}
                        </div>
                    </div>
                </div>
        </>
    )

  
     ///////////  MY FUNCTION ////////

     function myFunction() {
        var code = document.getElementById("code").value;
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();

        if (month.toString().length === 1) {
            month = '0' + month;
        }
        if (day.toString().length === 1) {
            day = '0' + day;
        }
        var dateto = year + '-' + month + '-' + day;
        // var dateto = "2022" + '-' + "01" + '-' + "2";

        fetch("http://111.118.177.198:8099/api/DashBoard/" + code + "/V/" + dateto + "").then((data) => {
            console.log(data);
            return data.json();
        }).then((objectData) => {
            var obj = JSON.parse(objectData);
            console.log(obj);
            let tableData = "";
            let Bank = "";
            let Pay = "";
            let Receipt = "";

            let Cash = 0.00;
            let card = 0.00;
            let payable = 0.00;
            let Recievable = 0.00;
            obj.map((values) => {
                Cash = values.Cash;
                card = values.Bank;
                payable = values.Payable;
                Recievable = values.Receivable;

                tableData = `${values.Cash}`;
                Bank = `${values.Bank}`;
                Pay = `${values.Payable}`;
                Receipt = `${values.Receivable}`;
            });
            // setData([
            //     { y: Cash, label: "Cash" },
            //     { y: card, label: "Bank" },
            //     { y: payable, label: "Payable" },
            //     { y: Recievable, label: "Reacivable" }
            // ])
            // document.getElementById("cash").innerHTML = tableData;
            // document.getElementById("bank").innerHTML = Bank;
            // document.getElementById("pay").innerHTML = Pay;
            // document.getElementById("Receivable").innerHTML = Receipt;

           

            var cap1 = "";
        var cap2 = "";
        var cap3 = "";
        var cap4 = "";
        var cap5 = "";
        var cap6 = "";
        var cap7 = "";
        var cap8 = "";
        let Sales1 = 0.00;
        let Sales2 = 0.00;
        let Sales3 = 0.00;
        let Sales4 = 0.00;
        let Sales5 = 0.00;
        let Sales6 = 0.00;
        let Sales7 = 0.00;
        let Sales8 = 0.00;
       

        // var code = document.getElementById("code").value;
        // var date = new Date();
        // var month = date.getMonth() + 1;
        // var day = date.getDate();
        // var year = date.getFullYear();

        // if (month.toString().length === 1) {
        //     month = '0' + month;
        // }
        // if (day.toString().length === 1) {
        //     day = '0' + day;
        // }
        // var dateto = year + '-' + month + '-' + day;

        //http://111.118.177.143/WebApi/api/DashBoard/ESpan2223/S/2022-10-22
        fetch("http://111.118.177.198:8099/api/DashBoard/" + code + "/S/" + dateto + "").then((data) => {
            //console.log(data);
            return data.json();
        }).then((objectData) => {
            var obj = JSON.parse(objectData);
            console.log(obj);
            let tableData = "";
            let i = 0;
            obj.map((values) => {
                var dateString = values.EntryDate;
                console.log("**********************");
                console.log(dateString);
                console.log("**********************");
    
                const D = new Date(dateString);
                console.log("********  d is **************" + D);
    
                const result = D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
                console.log("---------*  RE is -----------*" + result);
    
                if (i === 0) {
                    cap1 = result;
                    Sales1 = values.GrandTotal;
                }
                if (i === 1) {
                    cap2 = result;
                    Sales2 = values.GrandTotal;
                }
                if (i === 2) {
                    cap3 = result;
                    Sales3 = values.GrandTotal;
                }
                if (i === 3) {
                    cap4 = result;
                    Sales4 = values.GrandTotal;
                }
                if (i === 4) {
                    cap5 = result;
                    Sales5 = values.GrandTotal;
                }
                if (i === 5) {
                    cap6 = result;
                    Sales6 = values.GrandTotal;
                }
                if (i === 6) {
                    cap7 = result;
                    Sales7 = values.GrandTotal;
                }
                if (i === 7) {
                    cap8 = result;
                    Sales8 = values.GrandTotal;
                }
                i = i + 1;
            });
            var xValues = [cap1, cap2, cap3, cap4, cap5, cap6, cap7, cap8];
    
            console.log("@@@@@@@@@@ X VALUES" + xValues);
            const dates = xValues.map(dateString => {
                const dateParts = dateString.split("/");
                const year = parseInt(dateParts[2], 10);
                const month = parseInt(dateParts[0], 10) - 1;
                return new Date(year, month);
            });
    
            console.log("/*/*/*/*/* dates" + dates);
    
            // setData2([cap1, cap2, cap3, cap4, cap5, cap6, cap7, cap8]);
            setOption1(

                {
                    code:code,
                    cash: tableData,
                    bank: Bank,
                    pay: Pay,
                    receivable: Receipt,
                    option: {

                        animationEnabled: true,
                        title: {
                            text: "Email Categories",
                            horizontalAlign: "left"
                        },
                        data: [
                            {
                                type: "doughnut",
                                startAngle: 60,
                                indexLabelFontSize: 17,
                                indexLabel: "{label} - #percent%",
                                toolTipContent: "<b>{label}:</b> {y} (#percent%)",
                                dataPoints: [
                                    { y: Cash, label: "Cash" },
                                    { y: card, label: "Bank" },
                                    { y: payable, label: "Payable" },
                                    { y: Recievable, label: "Reacivable" }
                                ]
                            }
                        ]
                    },
                    option2:{
                        animationEnabled: true,
                        exportEnabled: true,
                        theme: "light2",
                        title: {
                          text: "Recent Sales"
                        },
                        axisY: {
                          title: "Sales",
                          includeZero: false
                        },
                        data: [
                          {
                            type: "spline",
                            color: "#FF0000",
                            dataPoints: [
                              { label: cap1, y: Sales1 },
                              { label: cap2, y: Sales2 },
                              { label: cap3, y: Sales3 },
                              { label: cap4, y: Sales4 },
                              { label: cap5, y: Sales5 },
                              { label: cap6, y: Sales6 },
                              { label: cap7, y: Sales7 },
                              { label: cap8, y: Sales8 }
                            ]
                          }
                        ]
                      }
                }

            );
            // setOption2(
    
            //     {
            //         animationEnabled: true,
            //         exportEnabled: true,
            //         theme: "light2",
            //         title: {
            //           text: "Recent Sales"
            //         },
            //         axisY: {
            //           title: "Sales",
            //           includeZero: false
            //         },
            //         data: [
            //           {
            //             type: "spline",
            //             color: "#FF0000",
            //             dataPoints: [
            //               { label: cap1, y: Sales1 },
            //               { label: cap2, y: Sales2 },
            //               { label: cap3, y: Sales3 },
            //               { label: cap4, y: Sales4 },
            //               { label: cap5, y: Sales5 },
            //               { label: cap6, y: Sales6 },
            //               { label: cap7, y: Sales7 },
            //               { label: cap8, y: Sales8 }
            //             ]
            //           }
            //         ]
            //       }
           
            
            // )
           
        }).catch((err) => {
            // alert(err);
            console.log(err);
        })

        }).catch((err) => {
            console.log(err);
        })

       
    }


    

}




export default DashBoard;