import React from 'react'
import Login from './components/Auth/Login'
import Home from './components/home/Home'
import SalesReturn from './components/report/SalesReturn'

export default function App() {
  return (
    <div>
        {/* <Login/> */}
        <Home/>
        {/* <SalesReturn/> */}
    </div>
  )
}
