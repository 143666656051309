import React, { useEffect, useState } from 'react'

import DashBoard from '../dashboard/DashBoard'
import SalesReturn from '../report/SalesReturn'



export default function Home() {

    const [option1, setOption1] = useState({
        code: "HAPPYHO2223",
        option: {},
        option2: {},
        cash: 0.00,
        bank: 0.00,
        pay: 0.00,
        receivable: 0.00
    })
    const [option2, setOption2] = useState({})

    const [menu, setMenu] = useState(0)


    return (
        <>
            <div class="sidebar">
                <div class="logo-details">
                    {/* <img src=" ProfitOne_logo.jpeg" alt="Trulli" width="30" height="30"/> */}
                    <span class="logo_name">ProfitOne ERP</span>
                </div>
                <ul class="nav-links">
                    <li>
                        <a className={menu == 0 ? 'active' : ""} onClick={() => setMenu(0)}>
                            <i class='bx bx-grid-alt' ></i>
                            <span class="links_name">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 1 ? 'active' : ""} onClick={() => setMenu(1)} >
                            <i class='bx bx-list-ul' ></i>
                            <span class="links_name">Sales Report</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 2 ? 'active' : ""} onClick={() => setMenu(2)}>
                            <i class='bx bx-list-ul' ></i>
                            <span class="links_name">Sales Return Report</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 3 ? 'active' : ""} onClick={() => setMenu(3)}>
                            <i class='bx bx-list-ul' ></i>
                            <span class="links_name">Purchase Report</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 4 ? 'active' : ""} onClick={() => setMenu(4)}>
                            <i class='bx bx-list-ul' ></i>
                            <span class="links_name">Purchase Return Report</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 5 ? 'active' : ""} onClick={() => setMenu(5)}>
                            <i class='bx bx-pie-chart-alt-2' ></i>
                            <span class="links_name">Profit Loss Report</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 6 ? 'active' : ""} onClick={() => setMenu(6)}>
                            <i class='bx bx-coin-stack' ></i>
                            <span class="links_name">Stock Report</span>
                        </a>
                    </li>

                    <li>
                        <a className={menu == 7 ? 'active' : ""} onClick={() => setMenu(7)}>
                            <i class='bx bx-user' ></i>
                            <span class="links_name">Payments</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 8 ? 'active' : ""} onClick={() => setMenu(8)}>
                            <i class='bx bx-user' ></i>
                            <span class="links_name">Receipts</span>
                        </a>
                    </li>
                    <li>
                        <a className={menu == 9 ? 'active' : ""} onClick={() => setMenu(9)}>
                            <i class='bx bx-user' ></i>
                            <span class="links_name">Journal</span>
                        </a>
                    </li>

                    <li>
                        <a className={menu == 10 ? 'active' : ""} onClick={() => setMenu(10)}>
                            <i class='bx bx-message' ></i>
                            <span class="links_name">Event Log</span>
                        </a>
                    </li>

                    <li>
                        <a className={menu == 11 ? 'active' : ""} onClick={() => setMenu(11)}>
                            <i class='bx bx-cog' ></i>
                            <span class="links_name">Setting</span>
                        </a>
                    </li>
                    <li class="log_out">
                        <a href="#">
                            <i class='bx bx-log-out'></i>
                            <span class="links_name">Log out</span>
                        </a>
                    </li>
                </ul>
            </div>
            <section class="home-section">
                <nav className='navs'>
                    <div class="sidebar-button">
                        <i class='bx bx-menu sidebarBtn'></i>
                        <span class="dashboard">{menu === 0 ? "DashBoard"
                            : menu === 1 ? "Sales Report"
                                : menu === 2 ? "Sales Return Report"
                                    : menu === 3 ? "Purchase  Report"
                                        : menu === 4 ? "Purchase Return Report"
                                            : menu === 5 ? "Profit Loss Report"
                                                : menu === 6 ? "Stock Report"
                                                    : menu === 7 ? "Payment"
                                                        : menu === 8 ? "Receipts"
                                                            : menu === 9 ? "Journal"
                                                                : menu === 10 ? "Event Logs"
                                                                    : "Settings"}</span>
                    </div>
                    <div class="search-box">
                        <input type="text" placeholder="Search..." />
                        <i class='bx bx-search' ></i>
                    </div>
                    <div class="profile-details">
                        {/* <!--<img src="images/profile.jpg" alt="">--> */}
                        {/* <div class="form-group col-md-4"> */}
                        <span for="code">Code</span>
                        <select name="code" id="code" class="form-control" onChange={myFunction}>
                            <option value="HAPPYHO2223">HAPPYHO2223</option>
                            <option value="HAPPYCB2223">HAPPYCB2223</option>
                            <option value="HAPPY2223">HAPPY2223</option>
                            <option value="HAPPYMKBA2223">HAPPYMKBA2223</option>
                        </select>
                        {/* <!--<input type="text" class="form-control" id="code" name="code" value="ESPAN2223"> --> */}
                        {/* </div> */}
                        <i class='bx bx-chevron-down' ></i>
                    </div>
                </nav>

                {/* <DashBoard/> */}
                {
                    menu === 0 ?
                        <DashBoard /> :
                        <SalesReturn type={menu === 1 ? "S" : menu === 2 ? "SR" : menu === 3 ? "P" : menu === 4 ? "PR" : ""} hide={true} />

                }


            </section>

        </>



    )
    ///////////  MY FUNCTION ////////

    function myFunction() {
        var code = document.getElementById("code").value;
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();

        if (month.toString().length === 1) {
            month = '0' + month;
        }
        if (day.toString().length === 1) {
            day = '0' + day;
        }
        var dateto = year + '-' + month + '-' + day;
        // var dateto = "2022" + '-' + "01" + '-' + "2";

        fetch("http://111.118.177.198:8099/api/DashBoard/" + code + "/V/" + dateto + "").then((data) => {
            console.log(data);
            return data.json();
        }).then((objectData) => {
            var obj = JSON.parse(objectData);
            console.log(obj);
            let tableData = "";
            let Bank = "";
            let Pay = "";
            let Receipt = "";

            let Cash = 0.00;
            let card = 0.00;
            let payable = 0.00;
            let Recievable = 0.00;
            obj.map((values) => {
                Cash = values.Cash;
                card = values.Bank;
                payable = values.Payable;
                Recievable = values.Receivable;

                tableData = `${values.Cash}`;
                Bank = `${values.Bank}`;
                Pay = `${values.Payable}`;
                Receipt = `${values.Receivable}`;
            });
            var cap1 = "";
            var cap2 = "";
            var cap3 = "";
            var cap4 = "";
            var cap5 = "";
            var cap6 = "";
            var cap7 = "";
            var cap8 = "";
            let Sales1 = 0.00;
            let Sales2 = 0.00;
            let Sales3 = 0.00;
            let Sales4 = 0.00;
            let Sales5 = 0.00;
            let Sales6 = 0.00;
            let Sales7 = 0.00;
            let Sales8 = 0.00;



            //http://111.118.177.143/WebApi/api/DashBoard/ESpan2223/S/2022-10-22
            fetch("http://111.118.177.198:8099/api/DashBoard/" + code + "/S/" + dateto + "").then((data) => {
                //console.log(data);
                return data.json();
            }).then((objectData) => {
                var obj = JSON.parse(objectData);
                console.log(obj);
                let tableData = "";
                let i = 0;
                obj.map((values) => {
                    var dateString = values.EntryDate;
                    console.log("**********************");
                    console.log(dateString);
                    console.log("**********************");

                    const D = new Date(dateString);
                    console.log("********  d is **************" + D);

                    const result = D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
                    console.log("---------*  RE is -----------*" + result);

                    if (i === 0) {
                        cap1 = result;
                        Sales1 = values.GrandTotal;
                    }
                    if (i === 1) {
                        cap2 = result;
                        Sales2 = values.GrandTotal;
                    }
                    if (i === 2) {
                        cap3 = result;
                        Sales3 = values.GrandTotal;
                    }
                    if (i === 3) {
                        cap4 = result;
                        Sales4 = values.GrandTotal;
                    }
                    if (i === 4) {
                        cap5 = result;
                        Sales5 = values.GrandTotal;
                    }
                    if (i === 5) {
                        cap6 = result;
                        Sales6 = values.GrandTotal;
                    }
                    if (i === 6) {
                        cap7 = result;
                        Sales7 = values.GrandTotal;
                    }
                    if (i === 7) {
                        cap8 = result;
                        Sales8 = values.GrandTotal;
                    }
                    i = i + 1;
                });
                var xValues = [cap1, cap2, cap3, cap4, cap5, cap6, cap7, cap8];

                console.log("@@@@@@@@@@ X VALUES" + xValues);
                const dates = xValues.map(dateString => {
                    const dateParts = dateString.split("/");
                    const year = parseInt(dateParts[2], 10);
                    const month = parseInt(dateParts[0], 10) - 1;
                    return new Date(year, month);
                });

                console.log("/*/*/*/*/* dates" + dates);

                // setData2([cap1, cap2, cap3, cap4, cap5, cap6, cap7, cap8]);

                setOption1(

                    {
                        code: code,
                        cash: tableData,
                        bank: Bank,
                        pay: Pay,
                        receivable: Receipt,
                        option: {

                            animationEnabled: true,
                            title: {
                                text: "Email Categories",
                                horizontalAlign: "left"
                            },
                            data: [
                                {
                                    type: "doughnut",
                                    startAngle: 60,
                                    indexLabelFontSize: 17,
                                    indexLabel: "{label} - #percent%",
                                    toolTipContent: "<b>{label}:</b> {y} (#percent%)",
                                    dataPoints: [
                                        { y: Cash, label: "Cash" },
                                        { y: card, label: "Bank" },
                                        { y: payable, label: "Payable" },
                                        { y: Recievable, label: "Reacivable" }
                                    ]
                                }
                            ]
                        },
                        option2: {
                            animationEnabled: true,
                            exportEnabled: true,
                            theme: "light2",
                            title: {
                                text: "Recent Sales"
                            },
                            axisY: {
                                title: "Sales",
                                includeZero: false
                            },
                            data: [
                                {
                                    type: "spline",
                                    color: "#FF0000",
                                    dataPoints: [
                                        { label: cap1, y: Sales1 },
                                        { label: cap2, y: Sales2 },
                                        { label: cap3, y: Sales3 },
                                        { label: cap4, y: Sales4 },
                                        { label: cap5, y: Sales5 },
                                        { label: cap6, y: Sales6 },
                                        { label: cap7, y: Sales7 },
                                        { label: cap8, y: Sales8 }
                                    ]
                                }
                            ]
                        }

                    }

                );


               

            }).catch((err) => {
                // alert(err);
                console.log(err);
            })





        }).catch((err) => {
            console.log(err);
        })

        //======================================


    }



}
